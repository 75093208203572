<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columns() {
      return this.block.fields.Columns;
    },
    columnsPerRow() {
      const rowString = this.block.fields.ColumnsPerRow?.value;
      const enums = { fourperrow: 4, threeperrow: 3, twoperrow: 2 };
      const rowValue = enums[rowString?.toLowerCase()];
      return rowValue || 2;
    },
  },
};
</script>

<template>
  <div :class="['_textColumns-container', `_textColumns-container-${columnsPerRow}`]">
    <div
      class="_textColumns-column"
      v-for="column in columns"
      :key="column"
      v-html="column.HtmlContent"
    >
    </div>
</div>
</template>

<style>
._textColumns-container {
    display: grid;
    width: 100%;
    padding: 0 1rem;
    gap: 1rem;
}

@media (--tabletAndDesktop) {
  ._textColumns-container-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  ._textColumns-container-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  ._textColumns-container-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--tablet) {
  ._textColumns-container-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
